/* Centraliza o container do calendário */

.alinhamento  {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

/* Estiliza o título */
.calendar-container h2 {
  margin-bottom: 20px;
  text-align: center;
}

/* Centraliza o calendário */
.fc {
  width: 100%;
  max-width: 900px; /* Limita a largura máxima do calendário */
  margin: 0 auto;
}

/* Estiliza o modal */
.modal-dialog {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 1rem); /* Garante que o modal esteja centralizado verticalmente */
}

.modal-content {
  width: 100%;
  max-width: 500px; /* Limita a largura máxima do modal */
  margin: 0 auto;
}

.modal-header{
  border-radius: 4px;
}
.modal-title{
  border-radius: 10px;
  height: 45px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnModal{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  /* border: 1px solid; */
  margin-top: 10px;
  gap: 10px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .fc {
    max-width: 100%; 
    height: 100%;/* Garante que o calendário ocupe toda a largura disponível em dispositivos móveis */
  }

  .modal-content {
    max-width: 90%; /* Ajusta a largura do modal em dispositivos móveis */
  }
}