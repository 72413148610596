.body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100dvh;
    margin: 0;
    font-size: 18pt;
}

.welcome-container {
    text-align: center;
}

.button-container {
    margin-top: 20px;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.blue-btn {
    background-color: #1a237e; /* Azul escuro */
    color: white;
}

.blue-btn:hover {
    background-color: #263492; /* Azul escuro mais escuro */
}

.orange-btn {
    background-color: #e65100; /* Laranja escuro */
    color: white;
}

.orange-btn:hover {
    background-color: #ff6d00; /* Laranja mais escuro */
}

@media (max-width: 720px) {
    .body{
        font-family: Arial, sans-serif;
        background-color: #f0f0f0; 
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100dvh;
        margin: 0;
        font-size: 18pt;
    }

    .button-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
    .btn {
        display: inline-block;
        padding: 10px 20px;
        width: 30%;
        font-size: 18px;
        text-decoration: none;
        border-radius: 5px;
        margin: 0 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}


