.service-request {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: auto;
}

.header h1 {
  color: #1c87c9;
  margin-left: 20px;
}

.request-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #1c87c9;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button:hover {
  background-color: #155b8a;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header h1 {
    margin-left: 0;
    margin-top: 10px;
  }

  .logo {
    width: 80px;
  }

  .input-field {
    font-size: 14px;
    padding: 8px;
  }

  .submit-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
