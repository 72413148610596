.body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin: 0;
}

.container {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
}

h1 {
    color: #003366;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    
}

label {
    margin-top: 10px;
}

input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #ff6600;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #e65c00;
}

a {
    color: #003366;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}


/* Adicione isso ao final do arquivo style.css */
select {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    color: #333;
    appearance: none; /* Remove a seta padrão do select no Chrome */
}

select:focus {
    border-color: #ff6600;
    outline: none;
}


/* Responsivo */
@media (max-width: 600px) {
    .container {
        padding: 15px;
        width: 90%;
    }

    input, button {
        font-size: 16px;
    }
}
