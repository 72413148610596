/* src/components/ConsumersManagement.css */

.consumers-management {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

header {
    background-color: #003366;
    color: #fff;
    padding: 15px;
    text-align: center;
}

main {
    padding: 20px;
}

.header-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.header-buttons button {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.header-buttons button:hover {
    background-color: #002244;
}

.consumers-list {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #003366;
    color: #fff;
}

button {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

button:hover {
    background-color: #002244;
}

.selected {
    background-color: #e0e0e0;
}
