/* BackButton.css */
.back-button {
    background-color: darkblue;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  