/* Estilos adaptados para CSS padrão */

body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }

  
  .login-container {
    width: 100%;
    height: 100dvh;
    top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login {
    width: 80%;
    max-width: 400px;
    background: #234195;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 5px 3px 3px 3px black;

    color: #fff;
  }
  
  .logo-login {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .logo-login img {
    max-width: 100%;
  }
  
  .login-content {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 2px solid #ccc;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
  }
  
  .input::placeholder {
    color: #ccc;
  }
  
  .botao-padrao {
    width: 60px;
    height: 60px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: #d75413;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .botao-login{
    width: 40%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #d75413;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .botao-login:hover{
    background-color: #0b256d;
    color: #d75413;
    box-shadow: 1px 1px 1px 1px black;
    transition: 0.5s;
  }

  .entrar-cadastrar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icons{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  
  .btnEsqueciSenha {
    background-color: transparent;
    color: orange;
    border: none;
    cursor: pointer;
  }

  .formForgetPassword{
    display: flex;
    
  }
  