.awaiting-acceptance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: #f4f4f4;
  text-align: center;
  padding: 20px;
}

.logo {
  width: 150px;
  margin-bottom: 30px;
}

.awaiting-acceptance h1 {
  color: #003366; /* Dark Blue */
  font-size: 2.5em;
  margin-bottom: 20px;
}

.pedido-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.pedido-details p {
  color: #555;
  font-size: 1.2em;
  margin: 10px 0;
}

.pedido-details strong {
  color: #003366; /* Dark Blue */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .awaiting-acceptance {
    padding: 10px;
  }

  .logo {
    width: 120px;
  }

  .awaiting-acceptance h1 {
    font-size: 2em;
  }

  .pedido-details {
    padding: 15px;
  }

  .pedido-details p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .awaiting-acceptance {
    padding: 5px;
  }

  .logo {
    width: 100px;
  }

  .awaiting-acceptance h1 {
    font-size: 1.5em;
  }

  .pedido-details {
    padding: 10px;
  }

  .pedido-details p {
    font-size: 0.9em;
  }
}