body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.no-scroll {
  background-color: #0b256d;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  max-width: 1000px;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 30%;
  padding: 2em;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: rgb(2, 2, 2) 0px 20px 30px -10px;
}

.text-center {
  text-align: center;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  display: block;
  margin-bottom: 0.5em;
}

.input {
  width: 100%;
  padding: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.texto-laranja {
  color: orange;
}

.error-box {
  color: red;
  font-weight: bold;
  display: none;
}

.cidade-estado {
  display: inline-block;
  margin-left: 0;
}

.dropdown-icon {
  display: inline-block;
  float: right;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 0.75em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.formerro {
  color: rgb(230, 149, 0);
  font-weight: 600;
  font-size: 0.9em;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .form-container {
      width: 40%;
  }
}

@media (max-width: 992px) {
  .form-container {
      width: 50%;
  }
}

@media (max-width: 768px) {
  .form-container {
      width: 70%;
  }
}

@media (max-width: 576px) {
  .form-container {
      width: 90%;
      padding: 1.5em;
  }

  .submit-button {
      padding: 0.5em;
  }

  .input {
      padding: 0.4em;
  }
}
