body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
}

.containerp {
    max-width: 900px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture {
    position: relative;
    margin-right: 20px;
}

.profile-picture img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-picture button {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: #003366;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.profile-infoP{
    display: flex;
    justify-content: center;
    gap: 5px;
}

.editable {
    margin-bottom: 10px;
}

.editable label {
    font-weight: bold;
    color: #003366;
}

.editable input,
.editable select {
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    margin: 5px 0;
    width: 100%;
}

.editable button {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 5px;
    margin-left: 5px;
    border-radius: 4px;
    cursor: pointer;
}

.profile-description {
    margin-bottom: 20px;
}

.profile-description button {
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.profile-description button i {
    margin-right: 5px;
}

.profile-achievements {
    margin-bottom: 20px;
}

.achievements {
    display: flex;
    gap: 10px;
}

.achievement {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.achievement i {
    font-size: 24px;
    color: #003366;
    margin-right: 10px;
}

.achievement p {
    color: #555;
}

.button-perfil-prestador {
    background-color: #ff6600;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.button-perfil-prestador i {
    margin-right: 5px;
}

.button-perfil-prestador {
    background-color: #e65c00;
}

.back-button {
    position: static;
    top: 20px;
    left: 20px;
    z-index: 1000; /* Para garantir que o botão esteja acima de outros elementos */
    display: inline-block;
    padding: 10px 20px;
    background-color: #003366;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #002245;
}

.back-button i {
    margin-right: 5px;
}


@media only screen and (max-width: 720px) {
    .profile-infoP {
        display: flex;
        flex-direction: column;
        align-items: stretch; /* Certifica-se de que os inputs ocupem toda a largura */
    }

    .editable {
        width: 100%; /* Garante que os elementos dentro de .editable ocupem toda a largura disponível */
    }

    .achievements .achievement {
        flex-basis: 100%; /* Faz com que cada achievement ocupe 100% da largura no mobile */
        margin-bottom: 10px; /* Adiciona espaço entre os itens */
    }

    .profile-picture {
        margin-bottom: 20px; /* Adiciona espaço entre a foto de perfil e as informações do perfil */
    }
}
