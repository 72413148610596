/* src/pages/Faturamento.css */

.faturamento-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .faturamento-card {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    color: #003366;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .faturamento-chart {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #003366;
  }
  