/* src/components/ServicesManagement.css */

.services-management {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

header {
    background-color: #003366;
    color: #fff;
    padding: 15px;
    text-align: center;
}

main {
    padding: 20px;
}

.header-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.header-buttons button {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.header-buttons button:hover {
    background-color: #002244;
}

.services-list {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

th {
    background-color: #003366;
    color: #fff;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content label {
    display: block;
    margin-bottom: 10px;
}

.modal-content input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-buttons button {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: #002244;
}
