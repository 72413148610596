body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
}

.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    color: #003366;
}

.servico {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.servico h2 {
    color: #003366;
}

.servico p {
    margin-top: 5px;
    color: #555;
}

.botoes {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.button-serviços-proximos {
    background-color: #ff6600;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.button-serviços-proximos {
    margin-right: 5px;
}

.button-serviços-proximos {
    background-color: #e65c00;
}

.back-button {
    position: static;
    top: 20px;
    left: 20px;
    z-index: 1000; /* Para garantir que o botão esteja acima de outros elementos */
    display: inline-block;
    padding: 10px 20px;
    background-color: #003366;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #002245;
}

.back-button i {
    margin-right: 5px;
}

.services-listp{
    gap: 10px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px #0000001a;
    padding: 20px;
    gap: 21px;
    display: flex;
    flex-direction: column;
}

.service-item{
    border: 1px solid;
    padding: 10px;
}

.botaoparaotatela{
    position: static;
    top: 20px;
    right: 20px;
    z-index: 1000; /* Para garantir que o botão esteja acima de outros elementos */
    display: inline-block;
    padding: 10px 20px;
    background-color: #003366;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.botaoparaotatela:hover{
    background-color: #002245;
}
