/* src/components/AdminDashboard.css */

.dashboard-container {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.dashboard-content{
    display: flex;
}

header {
    background-color: #003366;
    color: #fff;
    padding: 15px;
    text-align: center;
}

main {
    padding: 20px;
}

.dashboard, .online-list, .consumer-list {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 20px;
    position: relative;
}



h2 {
    color: #003366;
    margin-top: 0;
}
.dash{
    color: #ff6600;
    font-family: Arial, Helvetica, sans-serif;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.status-indicator {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
}

.status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
}

.online {
    background-color: green;
}

.offline {
    background-color: red;
}

.navigate-btn {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.navigate-btn:hover {
    background-color: #002244;
}

footer {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}

.btn {
    background-color: #003366;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.btn:hover {
    background-color: #002244;
}


@media screen and (max-width: 768px) {
    .dashboard-content{
        display: flex;
        flex-direction: column;
    }
}