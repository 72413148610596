body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.ongoing-service-container {
    padding: 20px;
    background-color: #fff;
    color: #333;
    max-width: 600px;
    margin: 50px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ongoing-service-container h1 {
    color: darkblue;
    text-align: center;
}

.service-details {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.service-item h2 {
    color: darkorange;
}

.service-item p {
    margin: 5px 0;
}

.service-value {
    font-size: 1.2em;
    color: darkblue;
    font-weight: bold;
}

.loading-bar-container {
    margin-top: 20px;
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    overflow: hidden;
    border-radius: 4px;
}

.loading-bar {
    width: 100%;
    height: 100%;
    background-color: #007bff;
    animation: loading 2s infinite;
}

@keyframes loading {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
