.map-container{
    height: 100dvh;
}
#map {
    height: 90dvh;
}

.content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #003366;
}

.butn {
    background-color: #ff6600;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 70px;
    height: 70px;
}

.butn:hover {
    background-color: #e65c00;
}

.butn i {
    font-size: 24px;
    margin-bottom: 5px;
}





@media (max-width: 768px) {
    #map {
        height: 80vh;
    }

    .content {
        flex-wrap: wrap;
        gap: 10px;
        padding: 5px;
    }

    .butn {
        width: 70px;
        height: 50;
        font-size: 18px;
    }

    .butn i {
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    #map {
        height: 85vh;
    }

    .content {
        gap: 5px;
        padding: 5px;
    }

    .butn {
        width: 70px;
        height: 70px;
        font-size: 16px;
    }

    .butn i {
        font-size: 16px;
    }
}
