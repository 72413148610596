body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    margin: 0;
    padding: 0;
}

.payment-container {
    padding: 20px;
    height: 50dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #333;
    max-width: 600px;
    margin: 50px auto;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-container h1 {
    color: darkblue;
    text-align: center;
}

.payment-method {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

.payment-method input[type="radio"] {
    display: none;
}

.payment-method label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    color: #333;
}

.payment-method label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid darkblue;
    border-radius: 50%;
    background: #fff;
}

.payment-method input[type="radio"]:checked + label::before {
    background: darkorange;
    border-color: darkorange;
}

.payment-method label::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%) scale(0);
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.2s ease;
}

.payment-method input[type="radio"]:checked + label::after {
    transform: translateY(-50%) scale(1);
}

.payment-details {
    margin: 10px 0;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
}

.payment-details.hidden {
    display: none;
}

label {
    display: block;
    margin: 10px 0 5px;
}

input[type="text"] {
    width: 100%;
    padding: 10px;
    margin: 5px 0 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.buttong {
    width: 100%;
    padding: 15px;
    background-color: darkblue;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.2em;
    cursor: pointer;
}

.buttong:hover {
    background-color: darkorange;
}

@media (max-width: 600px) {
    .payment-container {
        padding: 15px;
        margin: 20px;
    }

    .buttong {
        padding: 10px;
        font-size: 1em;
    }

    input[type="text"] {
        padding: 8px;
        margin: 5px 0 15px;
    }

    .payment-method label {
        padding-left: 30px;
    }

    .payment-method label::before {
        width: 18px;
        height: 18px;
    }

    .payment-method label::after {
        width: 8px;
        height: 8px;
    }
}
