

.service-info {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    color: #003366;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.button-container {
    text-align: center;
    margin-top: 20px;
}

.btn {
    padding: 10px 20px;
    background-color: #ff6600;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.btn:hover {
    background-color: #e65c00;
}
