.calendar-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* Título */
.calendar-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Estilo do FullCalendar */
.fc {
  max-width: 100%;
}

.fc-toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.fc-daygrid-day {
  height: 100px;
}

.fc-daygrid-day-number {
  float: right;
  margin: 5px;
  font-weight: bold;
}

.fc-daygrid-event {
  margin: 5px 0;
  padding: 2px 5px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
}

/* Estilo do modal */
.modal-header {
  background-color: #007bff;
  color: #fff;
}

.modal-title {
  margin: 0;
}

.modal-body p {
  margin: 5px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

/* Responsividade */
@media (max-width: 768px) {
  .calendar-container {
    padding: 10px;
  }

  .fc-toolbar {
    flex-direction: column;
    align-items: center;
  }

  .fc-daygrid-day {
    height: 80px;
  }

  .fc-daygrid-event {
    font-size: 10px;
  }
}